import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  input,
  output,
} from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';

import { LogoComponent } from '@rp/shared/ui';
import { ClockService } from '@rp/common/cdk';
import { Observable } from 'rxjs';
import { environment } from '@rp/environments';
import { IconComponent, IconName } from '@rp/shared/components';
import { DateFormat } from '@rp/shared/models';
import { TranslateModule } from '@ngx-translate/core';

import { MyBalanceComponent } from './components/my-balance/my-balance.component';
import { LanguagesComponent } from '../languages/languages.component';

@Component({
  selector: 'rp-header, [rpHeader]',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LogoComponent,
    MyBalanceComponent,
    AsyncPipe,
    DatePipe,
    IconComponent,
    LanguagesComponent,
    RouterLink,
    TranslateModule,
  ],
})
export class HeaderComponent {
  isDesktop = input.required({ transform: booleanAttribute });
  showBack = input(false, { transform: booleanAttribute });
  headerTitle = input<string>(null);
  isSidebarCollapsed = input(false, { transform: booleanAttribute });

  isSidebarCollapsedChange = output<boolean>();

  currentTime$: Observable<Date>;
  headerConfig = environment.uiConfig.header;
  icons = IconName;
  clockTimeFormat = DateFormat.TimeWithSeconds;

  private _clockService = inject(ClockService);

  constructor() {
    this.currentTime$ = this._clockService.nowSubject;
  }

  onSidebarToggle(): void {
    this.isSidebarCollapsedChange.emit(!this.isSidebarCollapsed());
  }

  onBack(): void {
    history.back();
  }
}
