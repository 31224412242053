import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory, provideCustomDateAdapter } from '@rp/utils';
import { languagesLocaleRecord, Languages } from '@rp/shared/models';
import { environment } from '@rp/environments';

export function localeFactory(): string {
  const translateService = inject(TranslateService);

  return languagesLocaleRecord[translateService.currentLang as Languages];
}

export const provideTranslate = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLanguage,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
        },
      }),
      MatNativeDateModule,
    ]),
    provideCustomDateAdapter(),
    {
      provide: MAT_DATE_LOCALE,
      useFactory: localeFactory,
    },
  ]);
