@if (isDesktop()) {
  <div class="header__wrapper-title">
    @if (showBack()) {
      <div class="header__wrapper-back" (click)="onBack()">
        <rp-icon class="header__arrow-back" [iconName]="icons.ArrowLeft" size="s" />
        <span class="header__back">
          {{ 'general.back' | translate }}
        </span>
      </div>
      <div class="header__vertical-slash"></div>
    }

    <span class="header__caption">
      {{ headerTitle() | translate }}
    </span>
  </div>
  <div class="header__right">
    @if (headerConfig.shouldShowMyBalance) {
      <rp-my-balance />
    }

    <div
      class="header__user-info"
      [routerLink]="headerConfig.haveAccountSettings ? ['profile'] : []"
      [class.with-redirect]="headerConfig.haveAccountSettings"
    >
      <rp-icon class="user-info__icon" [iconName]="icons.ProfileCircle" size="l" />
      <div class="user-info__content">
        <span class="user-info__label">My account</span>
        <span class="user-info__value">
          alex123&#64;gmail.com

          @if (headerConfig.haveAccountSettings) {
            <rp-icon class="user-info__icon" [iconName]="icons.Settings" size="s" />
          }
        </span>
      </div>
    </div>

    @if (headerConfig.havePersonalManager) {
      <div class="header__user-info">
        <rp-icon class="user-info__icon" [iconName]="icons.Avatar" size="l" />
        <div class="user-info__content">
          <span class="user-info__label">Your manager</span>
          <span class="user-info__value">Name Surname</span>
        </div>
      </div>
    }

    <rp-languages />

    <div class="header__time">
      <span>UTC</span>
      <span>{{ currentTime$ | async | date: clockTimeFormat : 'UTC' }}</span>
    </div>
  </div>
} @else {
  <rp-logo />
  <div class="header__right">
    @if (headerConfig.shouldShowMyBalance) {
      <rp-my-balance />
    }

    <div
      class="header__user-info"
      [routerLink]="headerConfig.haveAccountSettings ? ['profile'] : []"
      [class.with-redirect]="headerConfig.haveAccountSettings"
    >
      <rp-icon class="user-info__icon" [iconName]="icons.ProfileCircle" size="l" />
    </div>

    <div class="header__sidebar-toggle">
      <rp-icon
        class="sidebar-toggle__icon"
        (click)="onSidebarToggle()"
        [iconName]="isSidebarCollapsed() ? 'menu-hide' : 'burger-menu'"
        size="l"
      />
    </div>
  </div>
}
