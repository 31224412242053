import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'rp-my-balance',
  standalone: true,
  templateUrl: './my-balance.component.html',
  styleUrl: './my-balance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CurrencyPipe],
})
export class MyBalanceComponent {}
