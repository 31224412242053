import { HttpInterceptorFn } from '@angular/common/http';

import { environment } from '@rp/environments';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${environment.apiConfig.mockToken}`,
    },
  });

  return next(authReq);
};
